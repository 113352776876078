import React, { useEffect, useState } from "react";
import { LineChart, Line, XAxis, YAxis, Tooltip, CartesianGrid, ResponsiveContainer, Legend } from "recharts";
import { useParams } from "react-router-dom";
import { getDailyAverageAPI } from "../../../apis/plans.apis";
import { Card, CircularProgress, Typography } from "@mui/material";

const Linechart = () => {
  const { id } = useParams(); // Get billboard ID from URL
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await getDailyAverageAPI(id); // Fetch API data
        setData(response);
      } catch (error) {
        console.error("Error fetching daily average data:", error);
      } finally {
        setLoading(false);
      }
    }
    fetchData();
  }, [id]);

  return (
    <Card sx={{ p: 2, boxShadow: 3, borderRadius: 2 }}>
      <Typography variant="h6" textAlign="center">
        Daily Average Visibility Duration
      </Typography>
      {loading ? (
        <CircularProgress sx={{ display: "block", margin: "20px auto" }} />
      ) : (
        <ResponsiveContainer width="100%" height={400}>
          <LineChart data={data}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis label={{ value: "Minutes", angle: -90, position: "insideLeft" }} />
            <Tooltip />
            <Legend />
            <Line type="monotone" dataKey="avg_duration" stroke="#8884d8" name="Daily Avg Duration" />
            <Line type="monotone" dataKey="rolling_avg" stroke="#82ca9d" name="7-Day Rolling Avg" />
          </LineChart>
        </ResponsiveContainer>
      )}
    </Card>
  );
};

export default Linechart;
