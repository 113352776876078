import React, { useState } from "react";
import { useParams } from "react-router-dom";
import ScatterplotGraph from "./ScatterplotGraph";
import DayOfWeekBarChart from "./Dayofweekbarchart";
import MonthlyBoxPlot from "./MonthlyBoxPlot";
import Linechart from "./Linechart";
import { AppBar, Toolbar, Typography, Button, Box, Paper } from "@mui/material";

const ForecastVisibilityGraph = () => {
  const { id } = useParams(); // Get billboardId from URL
  const [selectedView, setSelectedView] = useState("daily");

  return (
    <Box>
      {/* Header */}
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" sx={{ flexGrow: 1 }}>
            Forecasted Visibility Graphs
          </Typography>
        </Toolbar>
      </AppBar>

      {/* View Selection Buttons */}
      <Box sx={{ display: "flex", justifyContent: "center", gap: 2, my: 3 }}>
        {["daily", "dayOfWeek", "monthly", "dailyAverage"].map((view) => (
          <Button
            key={view}
            variant={selectedView === view ? "contained" : "outlined"}
            onClick={() => setSelectedView(view)}
            sx={{ borderRadius: "20px", px: 3 }}
          >
            {view === "daily"
              ? "Daily"
              : view === "dayOfWeek"
              ? "Day of the Week"
              : view === "monthly"
              ? "Monthly"
              : "Daily Avg"}
          </Button>
        ))}
      </Box>

      {/* Graph Display */}
      <Paper>
        {selectedView === "daily" && <ScatterplotGraph billboardId={id} />}
        {selectedView === "dayOfWeek" && <DayOfWeekBarChart billboardId={id} />}
        {selectedView === "monthly" && <MonthlyBoxPlot billboardId={id} />}
        {selectedView === "dailyAverage" && <Linechart billboardId={id} />}
      </Paper>
    </Box>
  );
};

export default ForecastVisibilityGraph;
