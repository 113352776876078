import React from "react";

import SuperAdminLayout from "../../layouts/SuperAdminLayout";
import DateTimeSelection from "../../components/DateTimeSelection";

const DateSelection = () => {
	return (
		<SuperAdminLayout activeLink={"/datetime"}>
			<DateTimeSelection />
		</SuperAdminLayout>
	);
};

export default DateSelection;