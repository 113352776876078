import React from "react";
import Grid from "@mui/material/Grid";
import SuperAdminLayout from "../../layouts/SuperAdminLayout";
import AddCampaign from "./AddCampaign"; // Import the CampaignList component

const CampaignReport = () => {
	return (
		<SuperAdminLayout activeLink="/campaignreport">
			<Grid container>
				<Grid item xs={12}>
					<AddCampaign /> {/* Render the Campaign List */}
				</Grid>
			</Grid>
		</SuperAdminLayout>
	);
};



export default CampaignReport;
