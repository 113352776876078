import React, { useState } from "react";
import {
    Box, Button, Typography, FormControl, InputLabel, Select, MenuItem,
    Container, Chip, Stack, TextField, CircularProgress
} from "@mui/material";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { scheduleTaskAPI } from "../apis/scheduler.apis";
import { toast } from "react-toastify";

const ScheduleForm = () => {
    const [numDays, setNumDays] = useState(1);
    const [selectedTime, setSelectedTime] = useState(null);
    const [times, setTimes] = useState([]);
    const [loading, setLoading] = useState(false); 

    const handleAddTime = (time) => {
        if (time) {
            const formattedTime = time.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
            setTimes((prevTimes) => [...prevTimes, formattedTime]);
            setSelectedTime(null);
        }
    };

    const handleRemoveTime = (timeToRemove) => {
        setTimes((prevTimes) => prevTimes.filter((time) => time !== timeToRemove));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true); // Start loading
        try {
            const response = await scheduleTaskAPI(numDays, times);
    
            if (response.error) {
                throw new Error(response.error); // Force error handling if API returns an error
            }
    
            // Show success toast
            toast.success(response.message);
    
            const trafficJobs = response.next_scheduled_jobs?.filter(job =>
                job.job_id.startsWith("traffic_data_")
            );
            // Display the next scheduled traffic job
            if (trafficJobs?.length > 0) {
                toast.success(`${trafficJobs[0].next_run_time}`);
            } else {
                toast.info("No traffic data jobs scheduled.");
            }
        } catch (error) {
            toast.error(error.message || "Failed to schedule tasks"); // Show error toast
        } finally {
            setLoading(false); // Stop loading
        }
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Container maxWidth="sm">
                <Box sx={{ marginTop: 8, display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <Typography component="h1" variant="h5">Schedule Tasks</Typography>
                    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
                        <FormControl fullWidth sx={{ mt: 2 }}>
                            <InputLabel id="num-days-label">Number of Days</InputLabel>
                            <Select
                                labelId="num-days-label"
                                id="num-days"
                                value={numDays}
                                label="Number of Days"
                                onChange={(e) => setNumDays(e.target.value)}
                                disabled={loading} // Disable during loading
                            >
                                {[0,1, 2, 3, 4, 5, 6, 7].map((day) => (
                                    <MenuItem key={day} value={day}>{day} day(s)</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <Box sx={{ mt: 2 }}>
                            <TimePicker
                                label="Select Time"
                                value={selectedTime}
                                onChange={(newTime) => setSelectedTime(newTime)}
                                renderInput={(params) => <TextField {...params} fullWidth disabled={loading} />}
                            />
                            <Button variant="contained" onClick={() => handleAddTime(selectedTime)} sx={{ mt: 2 }} disabled={loading}>
                                Add Time
                            </Button>
                        </Box>
                        <Box sx={{ mt: 2 }}>
                            <Typography variant="body1">Selected Times:</Typography>
                            <Stack direction="row" spacing={1} sx={{ mt: 1 }}>
                                {times.map((time, index) => (
                                    <Chip key={index} label={time} onDelete={() => handleRemoveTime(time)} disabled={loading} />
                                ))}
                            </Stack>
                        </Box>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                            disabled={loading}
                        >
                            {loading ? <CircularProgress size={24} /> : "Schedule Tasks"}
                        </Button>
                    </Box>
                </Box>
            </Container>
        </LocalizationProvider>
    );
};

export default ScheduleForm;
