import React, { useEffect, useState } from "react";
import {
  ScatterChart,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Scatter,
  CartesianGrid,
  ZAxis,
} from "recharts";
import { Card, CircularProgress, Typography } from "@mui/material";
import { getScatterplotData } from "../../../apis/plans.apis";

const ScatterplotGraph = ({ billboardId }) => {
  const [ScatterplotData, setScatterplotData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      try {
        const data = await getScatterplotData(billboardId);
        const formattedData = data.map((item) => ({
          time: item.time,
          duration: Number(item.duration),
          intensity: Number(item.duration), // Intensity based on duration
        }));
        setScatterplotData(formattedData);
      } catch (error) {
        console.error("Error fetching Scatterplot data:", error);
      } finally {
        setLoading(false);
      }
    }
    fetchData();
  }, [billboardId]);

  return (
    <Card >
      <Typography variant="h6" textAlign="center">
        Scatterplot: Visibility Duration Over Time
      </Typography>
      {loading ? (
        <CircularProgress sx={{ display: "block", margin: "20px auto" }} />
      ) : (
        <ResponsiveContainer width="100%" height={400}>
          <ScatterChart>
            <CartesianGrid />
            <XAxis type="category" dataKey="time" name="Time" />
            <YAxis type="number" dataKey="duration" name="Duration (Seconds)" />
            <ZAxis type="number" dataKey="intensity" range={[0, 500]} />
            <Tooltip cursor={{ strokeDasharray: "3 3" }} />
            <Scatter name="Visibility" data={ScatterplotData} fill="#ff7300" />
          </ScatterChart>
        </ResponsiveContainer>
      )}
    </Card>
  );
};

export default ScatterplotGraph;
