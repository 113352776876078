import React, { useEffect, useState } from "react";
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, CartesianGrid } from "recharts";
import { getDayOfWeekData } from "../../../apis/plans.apis"; 
import { Card, CircularProgress, Typography } from "@mui/material";

const DayOfWeekBarChart = ({ billboardId }) => {
  const [chartData, setChartData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      try {
        const data = await getDayOfWeekData(billboardId);
        setChartData(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    }
    fetchData();
  }, [billboardId]);

  return (
    <Card sx={{ p: 2, borderRadius: 2 }}>
      <Typography variant="h6" textAlign="center">
        Visibility Duration by Day of the Week
      </Typography>
      {loading ? (
        <CircularProgress sx={{ display: "block", margin: "20px auto" }} />
      ) : (
        <ResponsiveContainer width="100%" height={400}>
          <BarChart data={chartData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="day" />
            <YAxis />
            <Tooltip />
            <Bar dataKey="total_duration" fill="#E622DB" />
          </BarChart>
        </ResponsiveContainer>
      )}
    </Card>
  );
};

export default DayOfWeekBarChart;
