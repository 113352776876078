import axios from "../libs/axios.lib";
import * as loginUtils from "../utils/login.utils";

export async function deleteCampaignAPI(campaign_id) {
	const token = loginUtils.getUser().token;

	await axios.delete("/campaigns/" + encodeURIComponent(campaign_id), {
		headers: {
			Authorization: token,
		},
	});

	return true;
}

export async function createCampaignByBriefId(body = {
    briefId:null, billboards: ""}) {
    const token = loginUtils.getUser().token;

    const {data} = await axios.post("/campaigns/create-by-brief-id",
        body, {
        headers: { Authorization: token },
    });
    return data;
}

export async function createCampaignManually(body = {billboards: ""}) {
    const token = loginUtils.getUser().token;

    const {data} = await axios.post("/campaigns/create-manual",
        body, {
        headers: { Authorization: token },
    });
    return data;
}


export async function getCampaignListAPI() {
	const token = loginUtils.getUser().token;

	const { data } = await axios.get("/campaigns/campaigns", {
		headers: {
			Authorization: token,
		},
	});

	return data;
}

export async function fetchBillboardsByBrief(briefId) {
    if (!briefId) throw new Error("Brief ID is required");
    const token = loginUtils.getUser().token;
    const data  = await axios.get("campaigns/by-brief/" + encodeURIComponent(briefId), {
		headers: {
			Authorization: token,
		},
	});
    console.log(data)
    return data;
};
