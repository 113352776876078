import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import BriefForm, {
	defaultBudget,
	defaultFormState,
} from "../../components/BriefForm";
import SuperAdminLayout from "../../layouts/SuperAdminLayout";

import { createBrief } from "../../apis/briefs.apis";

import "react-datepicker/dist/react-datepicker.css";


const CreateBrief = () => {
	const navigate = useNavigate();	

	const handleSubmit = async (fd) => {
		try {
			await createBrief(fd);
			toast.success("Created Successfully!");
			navigate("/");
		} catch (e) {
			let msg = "Something went wrong!";

			if (e.response && e.response?.data?.message) {
				msg = e.response?.data?.message;
			}

			toast.error(msg);
		}
	};

	return (
		<SuperAdminLayout activeLink={"/create-brief"}>
			<BriefForm
				initialBudgetState={[defaultBudget]}
				initialFormState={defaultFormState}
				onSubmit={handleSubmit}
			/>
		</SuperAdminLayout>
	);
};

export default CreateBrief;

