import axios from "../libs/axios.lib";

export async function scheduleTaskAPI(numDays, times) {
    try {

        console.log({numDays: numDays, times: times})
        const response = await axios.post("scheduler/schedule", {
            num_days: numDays,
            times: times,
        });

        return response.data;
    } catch (error) {
        console.error("Error scheduling tasks:", error);
        throw error;
    }
}
